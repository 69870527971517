/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f6f7fb;
  color: #333;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.ypl_text_center {
  text-align: center;
}

.ypl_text_font {
  font-size: 18px;
}

.ypl_track_text {
  font-weight: 600;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-image: -moz-linear-gradient(350deg,#be5656 2%,#ff0000);
  background-image: linear-gradient(100deg,#be5656 10%,#ff0000);
  font-size: 30px;
}

.ypl_serarch_btn {
  background-color: #3498db; /* Default blue color */
  border: none;
  border-radius: 5px;
  color: white; 
  cursor: pointer;
  font-size: 16px;
  padding: 12px 20px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  outline: none; /* To remove the default browser focus outline */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  text-transform: uppercase; /* Making the text uppercase for a modern look */
  letter-spacing: 1px;
}

.ypl_serarch_btn:hover {
  background-color: #2980b9; /* Slightly darker blue on hover */
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ypl_serarch_btn:active {
  background-color: #2471a3; /* Even darker shade for active state */
  transform: translateY(1px); /* Button press effect */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.ypl_searech_input {
  background-color: #f4f4f4; /* Light gray background */
  border: none;
  border-radius: 10px 0 0 10px;
  width: 30%;
  min-width: 60%;
  color: #333; /* Dark gray text color */
  font-size: 16px;
  padding: 0px 16px 0px 15px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  outline: none; /* To remove the default browser focus outline */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Inner shadow for a subtle depth */
}

.ypl_searech_input:focus {
  border-color: #3498db; /* Blue border on focus */
  background-color: #fff; /* White background on focus */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 5px rgba(52, 152, 219, 0.7); /* Maintaining inner shadow and adding a soft outer glow on focus */
}

.ypl_searech_input::placeholder {
  color: #999; /* Light gray placeholder text color */
}


input[type="text"], input[type="submit"] {
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ddd;
  transition: all 0.3s ease;
}

input[type="text"]:focus {
  border-color: #00aaff;
  box-shadow: 0 0 5px rgba(0, 170, 255, 0.5);
}

input[type="submit"] {
  background-color: #ff0000;
  color: #fff;
  border: none;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #be5656;
}

.timeline-list {
  list-style: none;
  padding: 0;
}

.timeline-item {
  position: relative;
  margin: 20px 0;
  padding-left: 30px;
}

.timeline-item_node {
  position: absolute;
  top: 0;
  left: 10px;
  width: 10px;
  height: 10px;
  background-color: #be5656;
  border-radius: 50%;
}

.timeline-item_timestamp {
  display: flex;
  justify-content: space-between;
}

.ypl_border_top {
  border-top: 1px solid #e6e6e6;
  margin-top: 20px;
  padding-top: 20px;
}
